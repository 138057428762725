<!-- Documentation link: https://kit.svelte.dev/docs/routing#page-page-svelte -->
<script>
  import { _ } from "svelte-i18n";
  import Search from "$lib/Search.svelte";
  import Streamers from "$lib/Streamers.svelte";
  import MovieList from "$lib/MovieList.svelte";
  import { settings_store } from "$lib/store.js";
  // import Keywords from "$lib/Keywords.svelte";

  export let data;

  const randomKey = `sample_prompt${Math.trunc(Math.random() * 70) + 1}`;
  $: prompt = $_(randomKey);
</script>

<!-- ---------------------------------------------------------------------- -->

{#if !prompt.startsWith("sample_") && $settings_store}
  <div id="search">
    <Search samplePrompt={prompt} cache={data} />
  </div>
  <Streamers />
  <MovieList />
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  #search {
    position: sticky;
    top: var(--header-height);
    z-index: 100;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, var(--background) 88%, rgba(0, 0, 0, 0) 100%);
    scroll-snap-align: start;
  }
</style>
