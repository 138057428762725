<script>
  import { onMount } from "svelte";
  // import { PUBLIC_ADSENSE_ID } from "$env/static/public";

  // export let active = false;

  function initAdsense(document) {
    // ----------------------------------------------------------------
    // ACTIVATE this code block to skip Adsense AFTER the Adsense functionality is fixed
    // otherwise it will only create more confusion.
    // ----------------------------------------------------------------
    // if (!$settings_store.consent_ad_cookies) {
    //   // console.log("Skipping ad cookies.");
    //   return;
    // }
    // ----------------------------------------------------------------
    let script = document.createElement("script");
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2331971626361986";
    script.crossorigin = "anonymous";

    document.body.appendChild(script);
  }

  onMount(() => {
    initAdsense(window.document);
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="ad banner1">
  <div class="adsense" id="adsense1">
    <!-- Square responsive -->
    <ins
      class="adsbygoogle"
      data-ad-client="ca-pub-2331971626361986"
      data-ad-slot="7426871018"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .ad {
    grid-column: 1 / 3;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .adsense {
    color: var(--mowizz-logo-blue);
    background-color: var(--dark-grey);
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0.5rem;
    overflow: hidden;
    aspect-ratio: 1/1;
    object-fit: scale-down;
  }
  .banner1 {
    grid-row: 3;
  }
  /* .banner2 {
    grid-column: -3 / -1;
    grid-row: 8;
  }
  .banner3 {
    grid-row: 13;
  } */
  .adsbygoogle {
    display: block;
  }
  @media (max-width: 863px) {
    .ad {
      place-items: center;
    }
    .adsense {
      width: 100%;
    }
    /* .banner2 {
      grid-row: 15;
    }
    .banner3 {
      grid-row: 27;
    } */
  }
  @media (max-width: 611px) {
    .ad {
      grid-column: 1;
      place-items: center;
    }
    .adsense {
      width: 100%;
    }
    /* .banner2 {
      grid-column: 1;
      grid-row: 20;
    }
    .banner3 {
      grid-row: 40;
    } */
  }
</style>
